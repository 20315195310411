<template>
  <div>
	<!-- 头部导航 -->
    <el-affix :z-index='2001'>
      <nav-bar></nav-bar>
    </el-affix>
	<!-- 工具栏 -->
	<tool-box></tool-box>
	<!-- 广告轮播加课程类型菜单 -->
	<div class='swiper-wrap container'>
		<div class='swiper-top'>
			<div class='top-title'>课程导航</div>
			<div class='top-list'>
				<ul>
					<li 
						@click='navToCourse(item)'
						:key='index'
						v-for="(item,index) in menusList">{{item.title}}</li>
				</ul>
			</div>
		</div>
		<div class='swiper-menus'>
			<div class='menus-swiper'>
				<ul class='menus'>
					<li
						class='menus-list'
						:key='index'
						@mouseenter="showSubtitle(item,index)"
						@mouseleave="hideSubtitle()"
						v-for="(item,index) in menusList">
						<div class="title">{{item.title}}</div>
						<div class='sub-icon'>
							<i class="el-icon-caret-right"></i>
						</div>
						<!-- 定位内容层 -->
						<div 
							class='sub-content' 
							v-show='subtitleActive==index&&subtitleFlag'
							v-if='currentSubList'>
							<h3 class='title'>{{currentSubList.title}}</h3>
							<div class='content'>
								<ul>
									<li 
										class='tag-list'
										@click='toCourseList(item)'
										v-for="(item,index) in currentSubList.children"
										:key="index">
										{{item.title}}
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class='swiper' >
				<swiper
					:slides-per-view="1"
					:space-between="0"
					:autoplay="{delay:3000,disableOnInteraction:false}"
					loop
					:pagination="{clickable:true}">
					<swiper-slide
						@click='adInfo(item)' 
						v-for='(item,index) in bannerList' 
						:key='index'>
						<img :src="item.thumb" alt="" :title="item.title">
					</swiper-slide>
				</swiper>
			</div>	
		</div>
	</div>
	
	<!-- 广告 -->
	<div class='course-ad'>
	  <div class='container'>
	    <el-row :gutter="5">
			<el-col
				:span="index < 2 ? 12 : 6"
				v-for="(item,index) in adImages"
				:key='index'>
				<div @click='adInfo(item)'>
					<img
					   class="ad-image"
					   :src="item.thumb"
					   :alt="item.title"
					   :style="{height:'100%',width:'100%'}"/>
				</div>
			</el-col>
	    </el-row>
	  </div>
	</div>
	
	<!-- 课程内容 -->
	<div class='recomment-wrap container'
		v-for='(item,index) in recommentList' 
		:key='index'>
		<div class='recomment-title'>
			<div class='title-list'>
				<div class='title first-spec'>{{item.title}}</div>
				<div class='sub-title'>
					<ul 
						class='sub-item' 
						v-for='(citem,cindex) in item.children' 
						:key='cindex'>
						<li 
							class='second-spec'
							:class="citem.checked?'secondSpecActive':''"
							@click="onCourseSpec(item,index,citem)">
							{{citem.title}}
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- 内容 -->
		<div class='recomment-content'>
			<div class='course-img'>
				<img 
					:src="currentImg[index].thumb" 
					title='' 
					alt=''>
			</div>
			<div class ='content-course'
				v-if='currentComboSpec[index].content'
				v-loading='currentComboSpec[index].loading'>
				<div class='course-list'>
					<el-row :gutter="10">
						<el-col 
							:span='8'
							v-for='(bitem,bindex) in currentComboSpec[index].content.comboclass' 
							:key='bindex'
						>
							<comboclass-item :item='bitem'></comboclass-item>
						</el-col>
					</el-row>
				</div>
			</div>
			<div v-else v-loading='true' class='content-course'></div>
			<div class='course-news'>
				<ul v-if='currentComboSpec[index].content'>
					<li 
						v-for='(nitem,nindex) in currentComboSpec[index].content.art'
						:key='nindex'>
						<h3 class='news-title'>{{nitem.title}}</h3>
						<div v-if='nitem.article.length>0' class='news-content'>
							<span :key='lindex'
								v-show='lindex<2'
								@click='toNewsDetail(litem)'
								v-for='(litem,lindex) in nitem.article'>
								{{litem.article_title}}
							</span>
						</div>
						<div v-else class='news-content'>
							<div class='no-news'>暂无资讯</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	
	<!-- 题库 -->
	<div class='paper-wrap container'>
		<div class='paper-title'>精品题库</div>
		<div class='paper-list'>
			<el-carousel 
				:interval="4000" 
				type="card" 
				height="370px">
				<el-carousel-item>
					<el-card class='box-card' shadow="always">
						<div class='list-item'>
							<div class='item-img'>
								<img src="../../assets/images/index/paper1.jpg">
							</div>
							<div class='item-info'>
								<div class='info-item'>
									<div class='paper-item-icon'>
										<svg-icon
											icon-class='paper-moni' 
											class='title-icon paper-moni'></svg-icon>
									</div>
									<div class="paper-item-title">模拟试题</div>
									<div class="paper-item-text">
										模拟真实考试现场，让你备考更轻松，
										考试通关更有把握！
									</div>
								</div>
								<div class="info-btn">
									<el-button
										:type='$config.buttonType'
										@click='toExamList(null, null, 7)'>进入题库</el-button>
								</div>
							</div>
						</div>
					</el-card>
				</el-carousel-item>
				<el-carousel-item>
					<el-card class='box-card' shadow="always">
						<div class='list-item'>
							<div class='item-img'>
								<img
									src="../../assets/images/index/paper2.jpg" 
									alt="">
							</div>
							<div class='item-info'>
								<div class='info-item'>
									<div class='paper-item-icon'>
										<svg-icon
											icon-class='paper-lianxi' 
											class='title-icon paper-lianxi'></svg-icon>
									</div>
									<div class="paper-item-title">每日一练</div>
									<div class="paper-item-text">
										依据新版无纸化考试标准，
										模拟考试流程，全面熟悉考试形式！
									</div>
								</div>
								<div class="info-btn">
									<el-button
										:type='$config.buttonType'
										@click='toExamList(null, null, 3)'>进入题库</el-button>
								</div>
							</div>
						</div>					
					</el-card>
				</el-carousel-item>
				<el-carousel-item>
					<el-card class='box-card' shadow="always">
						<div class='list-item'>
							<div class='item-img'>
								<img
									src="../../assets/images/index/paper3.jpg" 
									alt="">
							</div>
							<div class='item-info'>
								<div class='info-item'>
									<div class='paper-item-icon'>
										<svg-icon
											icon-class='paper-miya' 
											class='title-icon paper-miya'></svg-icon>
									</div>
									<div class="paper-item-title">密押试卷</div>
									<div class="paper-item-text">
										行业从教多年教师，根据每年考试试卷，
										分析命题规律，提高押题命中率!
									</div>
								</div>
								<div class="info-btn">
									<el-button
										:type='$config.buttonType'
										@click='toExamList(null, null, 2)'>进入题库</el-button>
								</div>
							</div>
						</div>					
					</el-card>
				</el-carousel-item>
				<el-carousel-item>
					<el-card class='box-card' shadow="always">
						<div class='list-item'>
							<div class='item-img'>
								<img
									src="../../assets/images/index/paper4.jpg" 
									alt="">
							</div>
							<div class='item-info'>
								<div class='info-item'>
									<div class='paper-item-icon'>
										<svg-icon
											icon-class='paper-chapter' 
											class='title-icon paper-chapter'></svg-icon>
									</div>
									<div class="paper-item-title">章节练习</div>
									<div class="paper-item-text">
										根据考试大纲，
										从海量试题中精选每个题型中的经典题，
										反复练习，轻松提升应考能力！
									</div>
								</div>
								<div class="info-btn">
									<el-button
										:type='$config.buttonType'
										@click='toExamList(null, null, 4)'>进入题库</el-button>
								</div>
							</div>
						</div>					
					</el-card>
				</el-carousel-item>
				<el-carousel-item>
					<el-card class='box-card' shadow="always">
						<div class='list-item'>
							<div class='item-img'>
								<img
									src="../../assets/images/index/paper5.jpg" 
									alt="">
							</div>
							<div class='item-info'>
								<div class='info-item'>
									<div class='paper-item-icon'>
										<svg-icon
											icon-class='paper-true' 
											class='title-icon paper-true'></svg-icon>
									</div>
									<div class="paper-item-title">往年真题</div>
									<div class="paper-item-text">
										收录归纳近10年考试真题，
										从题型和出题方向了解考试，
										更好的掌握考试节奏！
									</div>
								</div>
								<div class="info-btn">
									<el-button
										:type='$config.buttonType'
										@click='toExamList(null, null, 5)'>进入题库</el-button>
								</div>
							</div>
						</div>					
					</el-card>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
	<!-- footer -->
	<web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import { ElNotification } from 'element-plus';
import SwiperCore, { 
		Navigation, 
		Pagination, 
		Scrollbar, 
		A11y, 
		EffectFade, 
		Autoplay } from 'swiper';
import {Swiper,SwiperSlide} from 'swiper/vue'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]);	

import NavBar from '@/components/page/NavBar'
import ToolBox from '@/components/toolbox'
import WebFooter from '@/components/page/WebFooter'

import comboclassItem from '@/views/course/components/ComboClassItem6'

import {getSessionStorage,setSessionStorage} from '@/utils/auth'
import {mapState,mapMutations} from 'vuex';

import {
		getBanner,
		getMenus,
		getProSpec,
		getComboBySpec,
		getComboClass,
		getAdvertSpec
	} from '@/api/index6'

export default {
  data() {
    return {
		bannerList:[],
		subtitleFlag:false,
		subtitleActive:0,
		currentSubList:{},
		adImages:[],
		menusList:[],
		recommentList:[],
		currentImg:[],//当前封面
		currentComboSpec:[],//当前班型
		currentNews:[],//当前资讯
    }
  },
  components: {
    NavBar,
    ToolBox,
	Swiper,
	SwiperSlide,
	WebFooter,
	comboclassItem
  },
  computed:{
    ...mapState(['token']),
  },
  created(){
  	getBanner().then(res=>{//获取轮播图
  	  if(res.code==1){
  	    this.bannerList = res.data;
  	  }
  	});
	getMenus().then(res=>{//获取导航菜单
	  if(res.code==1){
	    this.menusList = res.data;
	  }
	});
	//获取广告
	getAdvertSpec().then(res=>{
	  if(res.code==1){
	    this.adImages = res.data;
	  }
	});
	getProSpec().then(res=>{  //行业分类，考试专题
	  if(res.code==1){
			this.recommentList = res.data;
			res.data.map((item,index)=>{
				item.children.map((citem,cindex)=>{
					if(cindex==0){
						citem.checked = true;
						this.currentImg.push(citem);
						this.currentComboSpec.push(citem);
					}else{
						citem.checked = false;
					}
				})
			});

			this.currentComboSpec.map((item,index)=>{
				getComboBySpec({
					spec_id:item.id
				}).then(res=>{
					if(res.code==1){
						let comboclass = res.data.comboclass.filter((fitem,findex)=>{
							return fitem.status==1
						});
						res.data.comboclass = comboclass;
						item.content = res.data;
					}
				});
			});
	  }
	});
	this.registerWindowResize();
  },
  methods:{
	adInfo(list){//轮播图广告跳转
		if(list.type == 1){//海报
	  
		}
		if(list.type == 2){//课程列表
			let idArr = list.urlId.split(",");
			this.$router.push({
			  path:'/course/' + idArr[0] + '/'+idArr[1]
			});
		}
	},
	navToCourse(item){
		if(item.children.length>0){
			let curChildren = item.children[0];
			this.$router.push({
				path:'/course/'+curChildren.pid+'/'+curChildren.id
			})
		}
	},
	toCourseList(item){//课程列表
		this.$router.push({
			path:'/course/'+item.pid+'/'+item.id
		})
	},
	toNewsDetail(nitem){//跳转新闻详情
		this.$router.push({
			path:'/newsdetail/'+ nitem.article_id
		});
	},
	showSubtitle(item,index){
		this.subtitleFlag = true;
		this.subtitleActive = index;
		this.currentSubList = item;
	},
	hideSubtitle(item){
		this.subtitleFlag = false;
		this.subtitleActive = 0;
		this.currentSubList = {};
	},
	moreCourseList(item){//更多
		let curChildren = item.children;
		curChildren.map((item,index)=>{
			if(item.checked){
				this.$router.push({
					path:'/course/'+item.pid+'/'+item.id
				})
			}
		})
	},
	onCourseSpec(fitem,findex,citem){
		this.recommentList.map((item,index)=>{
			if(fitem.id==item.id){
				item.children.map((sitem,sindex)=>{
					if(sitem.id==citem.id){
						sitem.checked = true;
						//切换封面
						this.currentImg[findex] = sitem;
						//切换数据
						getComboBySpec({
							spec_id:sitem.id
						}).then(res=>{
							if(res.code==1){
								let comboclass = res.data.comboclass.filter((fitem,findex)=>{
									return fitem.status==1
								});
								res.data.comboclass = comboclass;
								this.currentComboSpec[index].content = res.data;
								this.currentComboSpec[index].loading = true;
								let _this = this;
								setTimeout(function(){
									_this.currentComboSpec[index].loading = false;
								},350)
								
							}
						});
					}else{
						sitem.checked = false;
					}
				})
			}
		})
	},
	toSpecialPage(item){
		this.$router.push('/special/' + item.id)
	},
	toExamList(specId, childId, type) {//跳转题库
	  setSessionStorage('examListParams', {
	    specId: specId || this.recommentList[0].id,
	    specChildId: childId || this.recommentList[0].children[0].id,
	    type: type || 7,
	  })
	  this.$router.push('/examlist')
	},
	registerWindowResize(){
	  this.screenWidth = document.body.clientWidth;
	  this.screenHeight = document.body.clientHeight;
	  window.onresize = () => {
	    return (() => {
	      if(document.body.clientWidth > 1920){
	        this.screenWidth = 1920
	      }else if(document.body.clientWidth < 1200){
	        this.screenWidth = 1200
	      }else{
	        this.screenWidth = document.body.clientWidth;
	      }
	      this.screenHeight = document.body.clientHeight;
	    })();
	  };
	}
  }
}
</script>

<style>
	.swiper-pagination-clickable .swiper-pagination-bullet{
		width:40px;
		height:10px;
		border-radius: 10px;
	}
	.swiper-pagination-bullet-active{
		background: #fff;
	}
		
</style>
<style scoped lang="scss">
	//轮播图
	.swiper-wrap{
		overflow: hidden;
		box-sizing: border-box;
		margin-top:20px;
		display: flex;
		flex-direction: column;
		.swiper-top{
			display: flex;
			flex-direction: row;
			align-items: center;
			height:50px;
			margin-bottom: 10px;
			.top-title{
				margin-right:10px;
				width:160px;
				height:50px;
				box-sizing: border-box;
				line-height:50px;
				text-align: center;
				font-size:18px;
				background-color: $theme-color;
				color:#fff;
			}
			.top-list{
				flex:1;
				border-bottom:2px solid $theme-color;
				ul{
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size:16px;
					height:50px;
					li{
						height:40px;
						line-height:40px;
						margin:0px 10px;
						padding:0px 20px;
						cursor: pointer;
					}
					li:hover{
						background-color: #eee;
						color:$theme-color;
					}
				}
			}
		}
		.swiper-menus{
			display: flex;
			flex-direction: row;
			height: 350px;
			.menus-swiper{
				width:160px;
				height:350px;
				box-sizing: border-box;
				margin-right:10px;
				position: relative;
				background:$theme-color;
				display: flex;
				flex-direction: row;
				justify-content: center;
				.menus{
					width:160px;
					height:350px;
					overflow: hidden;
					padding:1px 0px 1px 2px;
					box-sizing: border-box;
					color:#fff;
					display: flex;
					flex-direction: column;
					align-items: center;
					.menus-list{
						width:160px;
						overflow: hidden;
						box-sizing: border-box;
						padding:0px 10px;
						flex:1;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						cursor: pointer;
				   }
				   li:hover{
					   color:$theme-color;
					   background-color: #fff;
					  
				   }
				}
				 // 定位内容层
				.sub-content{
					position: absolute;
					left:160px;
					top:0px;
					z-index: 999;
					background:#fff;
					overflow: hidden;
					box-sizing: border-box;
					width:700px;
					height: 350px;
					box-sizing: border-box;
					padding:30px 20px;
					border-left:none;
				   .title{
					   font-weight: bold;
					   margin-bottom:15px;
				   }
				   .content{
					   display: flex;
					   flex-direction: row;
					   align-items: center;
					   ul{
						   display: flex;
						   flex-direction: row;
						   align-items: center;
						   flex-wrap: wrap;
						   li{
							   padding:5px 15px;
							   margin-right:10px;
							   margin-bottom: 10px;
							   cursor: pointer;
							   border:1px solid #E8E8E8;
						   }
						   li:hover{
							   background-color:$theme-color;
							   color:#fff;
						   }
					   }
				   }
				} 
			}
			.swiper{
				flex:1;
				height:350px;
				overflow: hidden;
				box-sizing: border-box;
				cursor: pointer;
				img{
					height:350px;
				}
			}
		}
	}
	.course-ad{
		margin:20px auto;
		.ad-image{
		  transition-property: all;
		  transition-duration: .5s;
		  cursor: pointer;
		  transition-property:all;
		  transition-duration:0.5s;
		}
		.ad-image:hover{
		    box-shadow:0px 0px 3px 3px #aaa;
		}
	}

	// 课程分类
	.recomment-wrap{
		margin-top:30px;
		.recomment-title{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			background-color: #eee;
			
			height:50px;
			line-height: 50px;
			overflow: hidden;
			box-sizing: border-box;
			.title-list{
				height:50px;
				line-height: 50px;
				overflow: hidden;
				box-sizing: border-box;
				display: flex;
				flex-direction: row;
				align-items: center;
				.title{
					height:50px;
					overflow: hidden;
					box-sizing: border-box;
					font-size:20px;
					width:200px;
					padding-left:10px;
					border-bottom:2px solid $theme-color;
				}
				.sub-title{
					height:50px;
					overflow: hidden;
					box-sizing: border-box;
					display: flex;
					flex-direction: row;
					align-items: center;
					ul{
						display: flex;
						flex-direction: row;
						align-items: center;
						li{
							height:50px;
							overflow: hidden;
							box-sizing: border-box;
							cursor: pointer;
							padding:0px 25px;
							border-bottom:2px solid $theme-color;
						}
						li:hover{
							color:$theme-color;
						}
						li.secondSpecActive{
							border-left: 1px solid $theme-color;
							border-right: 1px solid $theme-color;
							border-top: 1px solid $theme-color;
							border-bottom: none;
							color:$theme-color;
							background-color: #fff;
						}
					}
				}
			}
			.more{
				height: 100%;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				color:$info-color;
				.more-icon{
					margin-left:5px;
				}
			}
			.more:hover{
				color:$theme-color;
			}
		}
		
		.recomment-content{
			margin-top:15px;
			height:335px;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			.course-img{
				width:200px;
				margin-right:10px;
				background-color: #fff;
				cursor: pointer;
			}
			.content-course{
				flex:1;
				height: 100%;
				margin-right:10px;
				.course-list{
					height: 100%;
					display: flex;
					flex-direction:column;
					.course-combo{
						width:100%;
					}
				}
			}
			.course-news{
				width:200px;
				overflow: hidden;
				box-sizing: border-box;
				background-color: #fff;
				padding:0px 10px;
				.news-title{
					padding-left:5px;
					border-left:2px solid $theme-color;
					margin:10px auto;
				}
				.news-content{
					display: flex;
					flex-direction: column;
					height:40px;
					font-size:12px;
					padding-left:5px;
					span{
						flex:1;
						overflow: hidden;
						text-overflow:ellipsis;
						white-space: nowrap;
						cursor: pointer;
					}
					span:hover{
						color:$theme-color;
					}
					.no-news{
						color:$info-color;
					}
				}
			}
		}
	}
	
	// 题库
	.paper-wrap{
		margin-top:50px;
		.paper-title{
			background-color: #eee;
			height:50px;
			line-height: 50px;
			overflow: hidden;
			box-sizing: border-box;
			font-size:20px;
			padding-left:10px;
			border-bottom:2px solid $theme-color;
		}
		.paper-list{
			margin-top:15px;
			.list-item{
				display: flex;
				flex-direction: row;
				padding:20px;
				.item-img{
					margin-right:20px;
				}
				.item-info{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					.info-item{
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: center;
						.paper-item-icon{
							font-size:45px;
							margin:10px auto;
							.paper-moni{
								color:$success-color;
							}
							.paper-lianxi{
								color:$danger-color
							}
							.paper-miya{
								color:$primary-color;
							}
							.paper-chapter{
								color:$warning-color;
							}
							.paper-true{
								color:#FF33CC;
							}
						}
						.paper-item-title{
							font-size:20px;
							font-weight:500;
							margin:10px auto;
						}
						.paper-item-text{
							padding:0px 20px;
							margin:10px auto;
							text-align: center;
							font-size:16px;
							line-height:25px;
						}
					}
					.info-btn{
						width:100%;
						display: flex;
						flex-direction: row;
						justify-content: center;
					}
				}
			}
		}
	}
</style>