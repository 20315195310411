<template>
	<div>
		<el-card 
			class="box-card" 
			@click='toCourseDetail(item)'
			shadow="hover">
			<h3 class='course-title'>{{item.title}}</h3>
			<div class='course-tag' v-if='item.service'>
				{{item.service}}
			</div>
			<div class='course-teachers'>
				<ul>
					<li 
						v-for='(titem,tindex) in item.teacher'
						:key='tindex'
						> 
						<div class='teacher-portrait'>
							<el-image
							  style="width:35px; height: 35px"
							  :src="titem.thumb"
							  fit="scale-down"></el-image>
						</div>
						<span class='teacher-name'>{{titem.name}}</span>
					</li>
				</ul>
			</div>
			<div class='course-pay'>
				<div class='pay-num'>{{item.num}}人购买</div>
				<div class='pay-price' v-if='item.combo'>
					<div class='price'>
						￥<span class='num'>{{item.combo[0].sale_price}}</span>
					</div>
					<span>起</span>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {setSessionStorage} from '@/utils/auth'
	export default{
		name:'comboclassItem',
		props:['item'],
		data(){
			return{
				
			}
		},
		created() {
			
		},
		methods:{
			toCourseDetail(item){//跳转课程详情
				if(item.combo.length>0){
					let defaultCombo = item.combo[0];
					this.$router.push({
						path:'/coursedetail/'+item.spec_id+'/'+item.id + '/'+defaultCombo.id
					})
				}
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.box-card{
		height: 160px;
		margin-bottom: 10px;
		box-sizing: border-box;
		padding:10px;
		transition-property:all;
		transition-duration:0.5s;
		.course-title{
			color:$text-color;
			overflow: hidden;
			height: 25px;
			line-height: 25px;
		}
		.course-tag{
			font-size:12px;
			height: 25px;
			line-height: 25px;
			color:$info-color;
		}
		.course-teachers{
			height:55px;
			margin:5px 0px;
			ul{
				display: flex;
				flex-direction: row;
				align-items: center;
				li{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin-right:15px;
					font-size:12px;
					.teacher-portrait{
						width:30px;
						height:30px;
						border-radius:100%;
						overflow: hidden;
						border:1px solid #eee;
						margin-bottom: 3px;
					}
				}
			}
		}
		.course-pay{
			display: flex;
			flex-direction:row;
			align-items: center;
			justify-content: space-between;
			flex:1;
			.pay-num{
				color:$text-color;
			}
			.pay-price{
				display: flex;
				flex-direction:row;
				align-items: center;
				color:$info-color;
				font-size:12px;
				.price{
					color:$price-color;
					.num{
						font-size:16px;
					}
				}
			}
		}
	}
	.box-card:hover{
		box-shadow:0px 0px 3px 3px #ccc;
	}
	.box-card:last{
		margin-right:0px;
	}
</style>
